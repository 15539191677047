import React from 'react';
import { ChangeEvent, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Container,
  Grid
} from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';
import Refresh from '../../../icons/Refresh';
import HealthCheckItemDescription from './HealthCheckItemDescription';
import { useApiMutation } from 'src/hooks/useApiMutation';

const HealthCheckItem: React.FC<{
  configItems: Array<{ key: string; value: string }>;
  isLoading: boolean;
}> = ({ configItems, isLoading }) => {
  const [inputValues, setInputValues] =
    useState<Array<{ key: string; value: string }>>(configItems);

  React.useEffect(() => {
    setInputValues(configItems);
  }, [configItems]);

  const [
    executeUpdate,
    { loading: loadingUpdate, data: dataUpdate, error: errorUpdate }
  ] = useApiMutation('healthcheck', 'HealthCheckUpdate', 'HealthCheck');

  const handleFormSubmit = async (values, actions) => {
    try {
      const variables = {
        items: values
      };
      executeUpdate({ variables });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card sx={{ width: '100%', mb: 4 }}>
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'wrap',
          pl: 0,
          pr: 0
        }}
      >
        <Formik
          initialValues={[]}
          onSubmit={(values, actions) => {
            values = inputValues?.map((item, index) => {
              return {
                key: item.key,
                value: item.value ? item.value.toString() : ''
              };
            });
            handleFormSubmit(values, actions);
          }}
        >
          <Form style={{ flexGrow: 1 }}>
            <Grid container sx={{ flexGrow: 1 }}>
              {isLoading ? (
                <Grid item md={6} xs={12} sx={{ flexGrow: 1 }}>
                  <Container>
                    <Typography
                      padding="10px 0 0 0"
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      Loading...
                    </Typography>
                  </Container>
                </Grid>
              ) : (
                inputValues?.map((item, index) => {
                  return [
                    <Grid
                      item
                      key={`item-${index}`}
                      md={6}
                      xs={12}
                      sx={{ flexGrow: 1 }}
                    >
                      <Container>
                        <Typography
                          padding="10px 0 0 0"
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {/* {item.key} */}
                          <HealthCheckItemDescription itemKey={item.key} />
                        </Typography>
                        <TextField
                          margin="normal"
                          name="valueItem"
                          value={item.value}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const newInputs = [...inputValues];
                            newInputs[index] = {
                              key: item.key,
                              value: e.target.value
                            };
                            setInputValues(newInputs);
                          }}
                          variant="outlined"
                          fullWidth
                          disabled={loadingUpdate}
                        />
                      </Container>
                    </Grid>
                  ];
                })
              )}
            </Grid>
            <Container sx={{ pt: 2, pb: 0, pl: 0, pr: 0 }}>
              <Button
                color="primary"
                endIcon={
                  loadingUpdate ? (
                    <Refresh fontSize="small" />
                  ) : (
                    <ArrowRightIcon fontSize="small" />
                  )
                }
                variant="outlined"
                type="submit"
                disabled={loadingUpdate || isLoading}
              >
                {loadingUpdate ? 'Updating' : 'Update Values'}
              </Button>
            </Container>
          </Form>
        </Formik>
      </CardContent>
    </Card>
  );
};

export default HealthCheckItem;
