import { useEffect, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import DateFnsUtils from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { useLazyQuery } from '@apollo/client';

import useSettings from '../../hooks/useSettings';
import { OrderData } from '../../components/dashboard/orders/UserOrders';
import OrdersListDataGrid from '../../components/dashboard/orders/OrderListDataGrid';
import OrdersListForm from '../../components/dashboard/orders/OrdersListForm';
import { GET_ORDERS, GET_ORDERS_COUNT } from '../../graphql/queries';
import { parseOrderData } from '../../utils/parseOrderData';

const pageSize = 50;

const OrdersList = () => {
  const { settings } = useSettings();
  const [getOrders, { loading, data, error }] = useLazyQuery(GET_ORDERS);

  const [
    getOrdersCount,
    { loading: loadingCount, data: dataCount, error: errorCount }
  ] = useLazyQuery(GET_ORDERS_COUNT);

  const [searchParams, setSearchParams] = useSearchParams();
  const [orderList, setOrderList] = useState<OrderData[]>([]);
  const [orderListCount, setOrderListCount] = useState<number>(0);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [skuMap, setSkuMap] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const res = parseOrderData(data && data?.GetOrders ? data.GetOrders : []);

    setSkuMap(res.skuMap);
    setOrderList(res.data);
  }, [data]);

  useEffect(() => {
    setOrderListCount((prevRowCountState) =>
      dataCount?.GetOrdersCount || dataCount?.GetOrdersCount === 0
        ? dataCount.GetOrdersCount
        : prevRowCountState
    );
  }, [dataCount]);

  const mergeOrderData = (orders: OrderData[]) => {
    let res: OrderData[] = [];
    for (let i = 0; i < orderList.length; i++) {
      const order = orderList[i];
      const updated = orders.find(
        (o) => o.confirmationCode === order.confirmationCode
      );
      if (updated) {
        res.push(updated);
      } else {
        res.push(order);
      }
    }

    setOrderList(res);
  };

  const changeTimezone = (date: Date, tzCode?: string) => {
    if (!tzCode) {
      return date;
    }

    const invdate = new Date(
      date.toLocaleString('en-US', {
        timeZone: tzCode
      })
    );

    var diff = date.getTime() - invdate.getTime();

    return new Date(date.getTime() - diff);
  };

  const DEFAULT_INPUT_VALUES = {
    confirmationCode: '',
    firstName: '',
    lastName: '',
    email: '',
    fulfilmentStatus: '',
    warehouse: '',
    orderBy: 'newestFirst',
    status: 'succeeded',
    paymentProvider: '',
    dateRange: 'LAST_30_DAYS',
    dateRangeStart: changeTimezone(
      new Date(new Date().setDate(new Date().getDate() - 30)),
      settings.timeZone
    ).toUTCString(),
    dateRangeEnd: changeTimezone(new Date(), settings.timeZone).toUTCString(),
    affiliateId: '',
    country: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmTerm: '',
    utmContent: '',
    dateRangeType: 'ORDERED_DATE'
  };

  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    DEFAULT_INPUT_VALUES
  );

  const resetForm = () => {
    setInputValues(DEFAULT_INPUT_VALUES);
    setSearchParams('');
  };

  const queryOrders = (page: number, query?: any, getAllPages = false) => {
    console.log('queryOrders', page, getAllPages);
    const variables = {
      ...inputValues,
      warehouse:
        inputValues.warehouse !== '' ? inputValues.warehouse : undefined,
      fulfilmentStatus:
        inputValues.fulfilmentStatus !== ''
          ? inputValues.fulfilmentStatus
          : undefined,
      status: inputValues.status !== '' ? inputValues.status : undefined,
      paymentProvider:
        inputValues.paymentProvider !== ''
          ? inputValues.paymentProvider
          : undefined
    };

    if (query) {
      Object.entries(query).forEach((q) => {
        variables[q[0]] = q[1];
      });
    }

    const { dateRange, ...filteredVariables } = variables;

    setSearchParams((params) => {
      const showColParams = [...params.entries()].filter((param) =>
        param[0].startsWith('col_')
      );
      const newParams = Object.keys(variables)
        .filter((k) => !!variables[k])
        .reduce((a, k) => ({ ...a, [k]: variables[k] }), {});

      for (const [key, value] of showColParams) {
        newParams[key] = value;
      }
      return newParams;
    });

    if (page === 0) {
      getOrdersCount({
        variables: filteredVariables
      });
    }

    getOrders({
      variables: {
        ...filteredVariables,
        skip: getAllPages ? 0 : page * pageSize,
        take: getAllPages ? 1000 : pageSize,
        sort: inputValues.orderBy
      }
    });
  };

  const handleOnPageChange = (page) => {
    console.log('page', page);
    if (
      !inputValues.confirmationCode &&
      !inputValues.email &&
      !(inputValues.dateRangeStart || inputValues.dateRangeEnd)
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
      setCurrentPage(page.page);
      queryOrders(page.page);
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Orders | Sens.ai</title>
      </Helmet>

      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 8
          }}
        >
          <Container maxWidth={settings.compact ? 'xl' : false}>
            <Grid container spacing={3}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={3}
                item
                xs={12}
              >
                <Grid item md={12} xs={12}>
                  <Typography color="textSecondary" variant="overline">
                    Orders List
                  </Typography>
                </Grid>

                <OrdersListForm
                  queryOrders={queryOrders}
                  inputValues={inputValues}
                  setInputValues={setInputValues}
                  searchParams={searchParams}
                  isFormValid={isFormValid}
                  setIsFormValid={setIsFormValid}
                  resetForm={resetForm}
                />

                <Grid item md={12} xs={12}>
                  <Card>
                    <OrdersListDataGrid
                      data={{ orders: orderList, skuMap: skuMap }}
                      loading={loading}
                      handleOnPageChange={handleOnPageChange}
                      mergeOrderData={mergeOrderData}
                      rowCount={orderListCount}
                      pageSize={
                        orderListCount && orderListCount < pageSize
                          ? orderListCount
                          : pageSize
                      }
                      queryOrders={queryOrders}
                      currentPage={currentPage}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default OrdersList;
