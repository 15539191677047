import { OrderData } from '../components/dashboard/orders/UserOrders';
import { Countries } from './Countries';

export const parseOrderData = (
  data: OrderData[]
): { data: OrderData[]; skuMap: Map<string, string> } => {
  const dRows: OrderData[] = [];
  const skuMapTemp = new Map<string, string>();
  skuMapTemp.set('SEN_HEADSET_V1', 'SEN_HEADSET_V1');
  skuMapTemp.set('SEN_HEADSET_V1_5X', 'SEN_HEADSET_V1_5X');
  data.forEach((d) => {
    const skus = {
      SEN_HEADSET_V1: 0,
      SEN_HEADSET_V1_5X: 0,
      sku3Quantity: 0,
      sku4Quantity: 0,
      sku5Quantity: 0,
      lineAmount: 0,
      shippingAmount: 0,
      tax1: 0,
      tax2: 0,
      tax3: 0,
      tax4: 0,
      tax5: 0
    };

    const lineItems = [...d.lineItems];
    lineItems.sort((a, b) =>
      (a.lineType + a.description).localeCompare(b.lineType + b.description)
    );
    let taxCount = 1;
    lineItems.forEach((l) => {
      if (
        l.lineType === 'product_shippable' ||
        l.lineType === 'product_none_shippable'
      ) {
        const prop = !skuMapTemp.has(l.sku)
          ? `sku${skuMapTemp.size + 1}Quantity`
          : skuMapTemp.get(l.sku);
        skus[prop] = l.quantity - l.refundQuantity - l.returnQuantity;
        skuMapTemp.set(l.sku, prop);
        skus.lineAmount += l.unitAmount * (l.quantity - l.refundQuantity);
      }
      if (l.lineType === 'discount') {
        skus.lineAmount =
          l.unitAmount < 0
            ? l.unitAmount + skus.lineAmount
            : skus.lineAmount - l.unitAmount;
      }
      if (l.lineType === 'shipping') {
        skus.shippingAmount += l.unitAmount;
      }
      if (l.lineType === 'sales_tax') {
        skus['tax' + taxCount] = l.unitAmount;
        taxCount++;
      }
    });

    const shippableLine = lineItems.find(
      (l) => l.lineType === 'product_shippable'
    );
    let valid = true;
    if (shippableLine) {
      const country = d.shippingAddress?.country;

      const countryInfo = country ? Countries.get(country) : null;

      const province = d.shippingAddress?.province;
      const postal = d.shippingAddress?.postal;
      if (!country || (!province && !countryInfo?.isEU) || !postal) {
        valid = false;
      } else {
        if (
          countryInfo &&
          countryInfo.postalRegex &&
          countryInfo.postalRegex !== ''
        ) {
          const ex = new RegExp(countryInfo.postalRegex);
          valid = ex.test(postal);
        }
      }
    }
    dRows.push({ ...d, ...skus, shippingAddressValidationFlag: !valid });
  });
  return { data: dRows, skuMap: skuMapTemp };
};
