import { FC, useEffect, useState } from 'react';
import { Card } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import useSettings from 'src/hooks/useSettings';

export const GET_USERS = gql`
  query GetUsers($input: GetUsersInput) {
    Users(input: $input) {
      id
      email
      firstName
      lastName
      dateOfBirth {
        formatted
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($email: String!) {
    User(email: $email) {
      id
      email
      firstName
      lastName
      nickname
      currentLevel
      expPoints
      dateOfBirth {
        formatted
      }
      unlockAccountAt {
        formatted
      }
      missions {
        id
        name
      }
      protocols {
        id
        name
      }
      features {
        id
        name
      }
      role {
        id
        name
      }
      headset {
        id
        created {
          formatted
        }
        mac
      }
      recentGuestSessions {
        id
        start {
          formatted
        }
        state
      }
      expiredMemberships {
        id
        created {
          formatted
        }
        updated {
          formatted
        }
        status
        level
        startDate {
          formatted
        }
        endDate {
          formatted
        }
        expiredSubscription {
          id
          created {
            formatted
          }
          updated {
            formatted
          }
          subscriptionId
          status
          provider
          period
          price
          currency
          currentPeriodEnd {
            formatted
          }
          cancelAtPeriodEnd
          providerCustomerId
          providerPaymentMethodId
          orderId
          email
          invoices {
            id
            created {
              formatted
            }
            updated {
              formatted
            }
            provider
            providerCustomerId
            providerInvoiceId
            providerEventType
            providerStatus
            status
            amountDue
            amountPaid
            attemptCount
            attempted
            paid
            billingReason
            hostedInvoiceUrl
            periodStart {
              formatted
            }
            periodEnd {
              formatted
            }
            currency
            tax
            total
            couponCode
            discountAmount
          }
        }
      }
      membership {
        id
        created {
          formatted
        }
        status
        level
        startDate {
          formatted
        }
        endDate {
          formatted
        }
        subscription {
          id
          created {
            formatted
          }
          subscriptionId
          status
          provider
          period
          price
          currency
          currentPeriodEnd {
            formatted
          }
          cancelAtPeriodEnd
          providerCustomerId
          providerPaymentMethodId
          orderId
          email
          couponCode
          couponNumberOfDays
          discountAmount
          invoices {
            id
            created {
              formatted
            }
            updated {
              formatted
            }
            provider
            providerCustomerId
            providerInvoiceId
            providerEventType
            providerStatus
            status
            amountDue
            amountPaid
            attemptCount
            attempted
            paid
            billingReason
            hostedInvoiceUrl
            periodStart {
              formatted
            }
            periodEnd {
              formatted
            }
            currency
            tax
            total
            couponCode
            discountAmount
          }
        }
      }
      orders {
        id
        email
        confirmationCode
        paymentProvider
        paymentIntentId
        status
        affiliateId
        fulfilmentStatus
        fulfilmentId
        fulfilmentWarehouseCode
        amount
        refundAmount
        currency
        created {
          formatted
        }
        updated {
          formatted
        }
        shippedDate {
          formatted
        }
        refundDate {
          formatted
        }
        lineItems {
          id
          description
          currency
          quantity
          unitAmount
          refundAmount
          productId
          lineNumber
          lineType
          sku
          refundQuantity
          returnQuantity
        }
        shippingAddress {
          country
          city
          postal
          street
          unit
          firstName
          lastName
          phone
          province
          created {
            formatted
          }
          updated {
            formatted
          }
        }
        billingAddress {
          country
          city
          postal
          street
          unit
          firstName
          lastName
          phone
          province
          created {
            formatted
          }
          updated {
            formatted
          }
        }
        tracking {
          id
          courierName
          serviceName
          trackingNumber
          trackingUrl
        }
      }
      metadata {
        id
        key
        value
      }
    }
  }
`;

interface UserInspectorProps {
  email: string;
}

const UserInspector = ({ email }: UserInspectorProps) => {
  const { settings } = useSettings();
  const [getUsers, { loading, data, error }] = useLazyQuery(GET_USER);

  useEffect(() => {
    getUsers({
      variables: { email: email }
    });
  }, []);

  useEffect(() => {
    if (data) {
      console.log('data', data);
    }
    if (error) {
      console.log('error', error);
    }
  }, [data, error]);

  return (
    <>
      {loading ? (
        <Card
          elevation={0}
          style={{
            height: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 222
          }}
        >
          <CircularProgress color="secondary" />
        </Card>
      ) : (
        <Card
          style={{
            height: '100%',
            overflow: 'auto',
            marginTop: 66,
            padding: 20
          }}
        >
          {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
        </Card>
      )}
    </>
  );
};

export default UserInspector;
