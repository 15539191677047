import { useState } from 'react';
import { useEffect } from 'react';
import { format } from 'date-fns';
import {
  Box,
  IconButton,
  Card,
  CardContent,
  Grid,
  Divider,
  Typography,
  Tab,
  Tabs,
  CircularProgress,
  Menu,
  MenuItem,
  Button,
  FormHelperText
} from '@mui/material';

import AddUserRole from './AddUserRole';
import UserSessions from './UserSessions';
import UnlockUserProtocols from './UnlockUserProtocols';
import UnlockUserMissionProtocols from './UnlockUserMissionProtocols';
import { OrderData } from '../orders/UserOrders';
import { ROLES, useAuthState } from 'src/contexts/authContext';
import UserMembership from './memberships/UserMemberships';
import PencilAlt from 'src/icons/PencilAlt';
import UpdateUserEmail from './UserUpdateEmail';
import OrdersListDataGrid from '../orders/OrderListDataGrid';
import UserExternalProfiles from './UserExternalProfiles';
import UserInvoices, { InvoiceData } from './UserInvoices';
import { TabPanel } from '../../shared/TabPanel';
import UserUnlocks from './UserUnlocks';
import UserCooldowns from './UserCooldowns';
import { useLocation } from 'react-router-dom';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import UserOrderGuestMerge from 'src/components/dashboard/orders/UserOrderGuestMerge';
import { parseOrderData } from 'src/utils/parseOrderData';
import { dateIsBefore } from 'src/utils/dates';
import UserMembershipHistory from './memberships/UserMembershipHistory';
import { useMutation } from '@apollo/client';
import { UNLOCK_ACCOUNT, RESET_GUEST_SESSIONS } from 'src/graphql/queries';
import UserMetadata from './UserMetadata';

export const parseUserInvoices = (membership: any, expiredMemberships: any) => {
  const invoices: InvoiceData[] = [];

  if (membership) {
    if (membership.subscription?.invoices) {
      membership.subscription?.invoices.forEach((invoice) => {
        invoices.push({
          ...invoice,
          subscriptionId: membership.subscription?.subscriptionId,
          period: membership.subscription?.period
        });
      });
    }
  }

  if (expiredMemberships) {
    expiredMemberships?.forEach((expiredMembership) => {
      if (expiredMembership?.expiredSubscription?.invoices) {
        expiredMembership?.expiredSubscription?.invoices.forEach((invoice) => {
          invoices.push({
            ...invoice,
            subscriptionId:
              expiredMembership.expiredSubscription?.subscriptionId,
            period: expiredMembership.expiredSubscription?.period
          });
        });
      }
    });
  }

  return invoices;
};

const UserDetails = ({
  executeGetUser,
  userLoading,
  userData,
  userError,
  usersLoading,
  usersData,
  handleClickInvoiceRefund,
  refundInvoiceLoading,
  handleClickExpiredMembership
}) => {
  const location = useLocation();

  const authState = useAuthState();

  const [numRecentGuestSessions, setNumRecentGuestSessions] =
    useState<number>(0);
  const [showEmailEdit, setShowEmailEdit] = useState<boolean>(false);
  const [orderList, setOrderList] = useState<OrderData[]>([]);
  const [skuMap, setSkuMap] = useState<Map<string, string>>(
    new Map<string, string>()
  );

  const [
    executeUnlockAccount,
    {
      loading: unlockAccountLoading,
      data: unlockAccountData,
      error: unlockAccountError
    }
  ] = useMutation(UNLOCK_ACCOUNT);

  const [
    executeResetGuestSessions,
    {
      loading: resetGuestSessionsLoading,
      data: resetGuestSessionsData,
      error: resetGuestSessionsError
    }
  ] = useMutation(RESET_GUEST_SESSIONS);

  const onClickUnlockAccount = () => {
    executeUnlockAccount({
      variables: {
        email: userData.User.email
      }
    });
  };

  const onClickResetGuestSessions = async () => {
    const isReset = await executeResetGuestSessions({
      variables: {
        email: userData.User.email
      }
    });

    if (isReset) {
      setNumRecentGuestSessions(0);
    }
  };

  useEffect(() => {
    if (unlockAccountData?.UnlockAccount) {
      executeGetUser({
        variables: {
          email: userData.User.email
        }
      });
    }
  }, [unlockAccountData]);

  const mergeOrderData = (orders: OrderData[]) => {
    let res: OrderData[] = [];
    for (let i = 0; i < orderList.length; i++) {
      const order = orderList[i];
      const updated = orders.find(
        (o) => o.confirmationCode === order.confirmationCode
      );
      if (updated) {
        res.push(updated);
      } else {
        res.push(order);
      }
    }

    setOrderList(res);
  };

  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMergeGuestOrder, setMergeGuestOrder] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const pathEmail = location.pathname.replace('/dashboard/users/', '');

    if (pathEmail && pathEmail !== 'details') {
      executeGetUser({
        variables: {
          email: pathEmail,
          fetchPolicy: 'no-cache'
        }
      });
    }
  }, []);

  useEffect(() => {
    if (userData?.User) {
      const orders = userData?.User?.orders ?? [];
      orders.forEach((o) => {
        o.user = userData.User;
      });
      const res = parseOrderData(orders);
      setSkuMap(res.skuMap);
      setOrderList(res.data);
      setShowEmailEdit(false);
      const currentMonth = new Date().getUTCMonth();
      const numGuestSessions =
        userData?.User?.recentGuestSessions.filter(
          (session) =>
            new Date(session.start.formatted).getUTCMonth() === currentMonth &&
            session.state === 'COMPLETE'
        ).length ?? 0;
      setNumRecentGuestSessions(numGuestSessions);
    }
  }, [userData]);

  const data = userData?.User;
  const noUsersFoundByName = usersData?.Users && usersData.Users.length === 0;

  console.log('userData', userData);

  return (
    <>
      {userLoading || usersLoading ? (
        <CircularProgress />
      ) : (
        data &&
        !noUsersFoundByName && (
          <>
            <Grid container mb={3}>
              <Grid item xs={12}>
                <Typography fontSize="" color="textPrimary" variant="h5">
                  User Details: {data.firstName} {data.lastName}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{ mt: 3 }}>
                  <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item>
                      <Typography
                        sx={{ mt: 1, mb: 1, ml: 2 }}
                        color="textSecondary"
                        variant="overline"
                      >
                        User Details
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button'
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: 48 * 4.5,
                            width: '30ch'
                          }
                        }}
                      >
                        {authState.minAuthLevelMet(ROLES.CSA_ADMIN) && (
                          <MenuItem
                            key="merge-guest-order"
                            onClick={() => {
                              handleClose();
                              setMergeGuestOrder(true);
                            }}
                          >
                            Merge Guest Order
                          </MenuItem>
                        )}
                      </Menu>
                    </Grid>
                  </Grid>

                  <Divider />
                  <CardContent
                    sx={{
                      alignItems: 'center',
                      flex: '1 0 auto'
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Email
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.email}
                          {authState.minAuthLevelMet(ROLES.CSA_MANAGER) && (
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setShowEmailEdit(true);
                              }}
                            >
                              <PencilAlt fontSize="small" />
                            </IconButton>
                          )}
                        </Typography>

                        {showEmailEdit && (
                          <UpdateUserEmail
                            email={data.email}
                            onDone={(newEmail) => {
                              setShowEmailEdit(false);
                              executeGetUser({
                                variables: {
                                  email: newEmail,
                                  fetchPolicy: 'no-cache'
                                }
                              });
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          First Name
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.firstName}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Last Name
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.lastName}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Nickname
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.nickname}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          ID
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.id}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Date of Birth
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {(() => {
                            if (!data.dateOfBirth) {
                              return 'Not set';
                            }
                            const dateOfBirth = new Date(
                              data.dateOfBirth.formatted
                            );
                            return `${dateOfBirth.toLocaleString('default', {
                              timeZone: 'UTC',
                              month: 'long'
                            })} ${dateOfBirth.getUTCDate()}, ${dateOfBirth.getUTCFullYear()}`;
                          })()}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Level
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.currentLevel}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Experience Points
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.expPoints}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Roles
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.role.map((r) => r.id).join(', ')}
                        </Typography>
                      </Grid>

                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Owned Headset MAC Address
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {data.headset.length > 0
                            ? data.headset[0].mac
                            : 'No headset registered'}
                        </Typography>
                        {data.headset.length > 0 && (
                          <Typography color="textSecondary" variant="body2">
                            {data.headset[0].trialActivated
                              ? 'Trial activated'
                              : 'Trial not activated'}
                          </Typography>
                        )}
                      </Grid>

                      {data.lastUsedMacAddress && (
                        <Grid item md={3} xs={12}>
                          <Typography color="textPrimary" variant="subtitle2">
                            Last Used Headset MAC Address
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            {data.lastUsedMacAddress}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Account Locked Until
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {dateIsBefore(
                            new Date(data.unlockAccountAt.formatted),
                            new Date()
                          )
                            ? 'Account is not locked'
                            : format(
                                new Date(data.unlockAccountAt.formatted),
                                'MM/dd/yyyy HH:mm:ss'
                              )}
                        </Typography>
                      </Grid>

                      <Grid item sx={{ mb: 2 }} md={3} xs={12}>
                        <Typography color="textPrimary" variant="subtitle2">
                          Unlock Account
                        </Typography>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={onClickUnlockAccount}
                          disabled={
                            unlockAccountLoading ||
                            dateIsBefore(
                              new Date(data.unlockAccountAt.formatted),
                              new Date()
                            )
                          }
                        >
                          Unlock
                        </Button>
                      </Grid>
                    </Grid>

                    {unlockAccountError && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>
                          {unlockAccountError.message}
                        </FormHelperText>
                      </Box>
                    )}
                    <Grid
                      sx={{ mb: 2 }}
                      item
                      md={3}
                      xs={12}
                      justifyContent="center"
                    >
                      <Box
                        sx={{
                          display: 'flex'
                        }}
                      ></Box>
                    </Grid>

                    <Divider sx={{ mt: 2, mb: 2 }} />

                    {data && data.id && (
                      <Grid container spacing={3}>
                        <UserExternalProfiles userEmail={data.email} />
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Card sx={{ mt: 3 }}>
              <CardContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Membership" />
                    {/* <Tab label="Invoices" /> */}
                    <Tab label="Membership History" />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                  <UserMembership
                    user={data}
                    membershipData={data?.membership}
                    expiredMemberships={data?.expiredMemberships}
                    loading={userLoading}
                    handleClickInvoiceRefund={handleClickInvoiceRefund}
                    refundInvoiceLoading={refundInvoiceLoading}
                    userLoading={userLoading}
                    refreshUser={(email: string) => {
                      executeGetUser({
                        variables: {
                          email: email,
                          fetchPolicy: 'no-cache'
                        }
                      });
                    }}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <UserMembershipHistory
                    data={data}
                    handleClickExpiredMembership={handleClickExpiredMembership}
                  />
                </TabPanel>
              </CardContent>
            </Card>

            {!userLoading && data && (
              <UserUnlocks loading={userLoading} user={data} />
            )}

            {!userLoading && data && (
              <UserCooldowns loading={userLoading} user={data} />
            )}

            <Card sx={{ mt: 3 }}>
              <Card>
                <Typography
                  sx={{ mt: 1, mb: 1, ml: 2 }}
                  color="textSecondary"
                  variant="overline"
                >
                  Orders
                </Typography>

                <Divider />
                <OrdersListDataGrid
                  data={{ orders: orderList, skuMap: skuMap }}
                  loading={userLoading}
                  handleOnPageChange={(p) => {}}
                  mergeOrderData={mergeOrderData}
                  rowCount={orderList.length}
                  pageSize={orderList.length}
                />
              </Card>
            </Card>

            {!userLoading && data && (
              <Card sx={{ mt: 3 }}>
                <UserSessions
                  email={data.email}
                  authState={authState}
                  numRecentGuestSessions={numRecentGuestSessions}
                  resetGuestSessionsLoading={resetGuestSessionsLoading}
                  onClickResetGuestSessions={onClickResetGuestSessions}
                />
              </Card>
            )}

            {!userLoading &&
              data &&
              (authState.isRole(ROLES.DEV_ADMIN) ||
                authState.isRole(ROLES.ADMIN)) && (
                <UserMetadata
                  metadata={data.metadata}
                  userId={data.id}
                  userEmail={data.email}
                  executeGetUser={executeGetUser}
                />
              )}

            <Card sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                {authState.roles.indexOf('ADMIN') >= 0 && (
                  <Grid item md={6} xs={12}>
                    <UnlockUserProtocols email={data.email} />
                  </Grid>
                )}
                {authState.roles.indexOf('ADMIN') >= 0 && (
                  <Grid item md={6} xs={12}>
                    <UnlockUserMissionProtocols email={data.email} />
                  </Grid>
                )}
              </Grid>
              <AddUserRole email={data.email} />
            </Card>
          </>
        )
      )}
      {showMergeGuestOrder && data && (
        <UserOrderGuestMerge
          user={data}
          onClose={() => {
            setMergeGuestOrder(false);
          }}
        />
      )}
    </>
  );
};

export default UserDetails;
