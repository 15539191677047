import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@mui/material';
import { HealthCheckItem } from '../../components/dashboard/healthcheck';
import useSettings from '../../hooks/useSettings';
import { useAuthState } from '../../contexts/authContext';
import { useApiQuery } from 'src/hooks/useApiQuery';

const HealthCheck = () => {
  const { loading, error, data } = useApiQuery('healthcheck', 'HealthCheck', {
    keys: [
      'firmwareVersionSoft',
      'firmwareVersionLatest',
      'firmwareVersionSoftBeta',
      'firmwareVersionLatestBeta',
      'firmwareVersionHard',
      'iosVersionSoft',
      'iosBuildNoSoft',
      'iosVersionHard',
      'iosBuildNoHard',
      'androidVersionSoft',
      'androidBuildNoSoft',
      'androidVersionHard',
      'androidBuildNoHard',
      'androidStoreUrl',
      'iosStoreUrl',
      'messageSoftUpdate',
      'messageForceUpdate',
      'messageFirmwareSoftUpdate',
      'messageFirmwareForceUpdate',
      'messageFirmwareSoftUpdateBeta'
    ]
  });

  const { settings } = useSettings();
  const authState = useAuthState();

  const parseConfig = (keyStartsWith: string) => {
    if (!data?.HealthCheck) return [];
    return data?.HealthCheck.filter((item) =>
      item.key.startsWith(keyStartsWith)
    );
  };
  const parseFirmwareConfigs = () => [
    ...parseConfig('firmware'),
    ...parseConfig('messageFirmware')
  ];
  const parseIosConfigs = () => parseConfig('ios');
  const parseAndroidConfigs = () => parseConfig('android');
  const parseOtherConfigs = () => {
    if (!data?.HealthCheck) return [];
    return data?.HealthCheck.filter(
      (item) =>
        !item.key.startsWith('firmware') &&
        !item.key.startsWith('messageFirmware') &&
        !item.key.startsWith('ios') &&
        !item.key.startsWith('android')
    );
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: HealthCheck | Sens.ai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={1}
            item
            xs={12}
          >
            <Grid item>
              <Typography fontSize="" color="textPrimary" variant="h5">
                HealthCheck
              </Typography>
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={1}
            item
            xs={12}
          >
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography color="textSecondary" variant="overline">
                Firmware Configs
              </Typography>
              {authState.roles?.includes('BETA_FIRMWARE') && (
                <p>
                  *** WARNING YOU ARE A BETA USER THESE VALUES MAY NOT BE
                  ACCURATE ***
                </p>
              )}
              <HealthCheckItem
                configItems={parseFirmwareConfigs()}
                isLoading={loading}
              />
              <Typography color="textSecondary" variant="overline">
                iOS Configs
              </Typography>
              <HealthCheckItem
                configItems={parseIosConfigs()}
                isLoading={loading}
              />
              <Typography color="textSecondary" variant="overline">
                Android Configs
              </Typography>
              <HealthCheckItem
                configItems={parseAndroidConfigs()}
                isLoading={loading}
              />
              <Typography color="textSecondary" variant="overline">
                Other Configs
              </Typography>
              <HealthCheckItem
                configItems={parseOtherConfigs()}
                isLoading={loading}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default HealthCheck;
